import MicroModal from 'micromodal'
import { get, unformatCurrency } from '@/utils'

// options for MicroModal
const MicroModalOpts = {
  disableScroll: true,
  awaitOpenAnimation: true,
  awaitCloseAnimation: true,
  onClose(modal) {
    modal.dispatchEvent(new CustomEvent('modalclose', {
      detail: {
        id: modal.id.replace('modal-', ''),
      },
    }));
  }
};

// v-open-modal directive
export const OpenModalDirective = {
  beforeMount(el, binding) {
    function openModalEventHandler(id) {
      MicroModal.show(`modal-${id}`, MicroModalOpts);
    }
    
    el._openModalEventHandler = openModalEventHandler.bind(el, binding.value);
    el.addEventListener('click', el._openModalEventHandler);
  },
  unmounted(el) {
    el.removeEventListener('click', el._openModalEventHandler);
    el._openModalEventHandler = null;
  },
};

// modal open/close mixins
export const ModalMixin = {
  methods: {
    openModal(id) {
      MicroModal.show(`modal-${id}`, MicroModalOpts);
    },
    closeModal(id) {
      MicroModal.close(`modal-${id}`);
    },
  },
};

// v-table-sort directive
export const TableSortDirective = {
  beforeMount(el, binding) {
    el._sortFunction = binding.value;
  },
};

// v-column-sort directive
export const ColumnSortDirective = {
  beforeMount(el, binding, vnode) {
    el.classList.add('v-sortable');

    const modifiers = Object.keys(binding.modifiers).join('.');
    const sortKey = (binding.arg || binding.value) + (modifiers ? `.${modifiers}` : '');

    // click handler
    function sortClassEventHandler(sortKey, context, vnode) {
      // string sorting function
      const stringSortFn = (a, b) => {
        const [keyA, keyB] = [get(a, [sortKey], ''), get(b, [sortKey], '')];
        if (this.classList.contains('v-sortable--sort-up')) {
          return keyA.localeCompare(keyB);
        }

        return keyB.localeCompare(keyA);
      };

      // number sorting function
      const numberSortFn = (a, b) => {
        const [keyA, keyB] = [get(a, [sortKey]), get(b, [sortKey])];
        if (this.classList.contains('v-sortable--sort-up')) {
          return keyA - keyB;
        }

        return keyB - keyA;
      };

      // money sorting function
      const moneySortFn = (a, b) => {
        const [keyA, keyB] = [
          unformatCurrency(get(a, [sortKey])),
          unformatCurrency(get(b, [sortKey])),
        ];

        if (this.classList.contains('v-sortable--sort-up')) {
          return keyA - keyB;
        }

        return keyB - keyA;
      };

      // remove any sort class from previous nodes
      const previous = this.parentNode._curSortKey;
      if (previous && previous !== this) {
        previous.classList.remove('v-sortable--sort-up', 'v-sortable--sort-down');
      }

      // update current sort key
      this.parentNode._curSortKey = this;

      if (this.classList.contains('v-sortable--sort-up')) {
        this.classList.replace('v-sortable--sort-up', 'v-sortable--sort-down');
      }
      //
      else if (this.classList.contains('v-sortable--sort-down')) {
        this.classList.replace('v-sortable--sort-down', 'v-sortable--sort-up');
      }
      //
      else {
        this.classList.add('v-sortable--sort-up');
      }

      // figure out which sorting function to use based on data type
      let sortFn = null;
      if (this.classList.contains('string')) {
        sortFn = stringSortFn;
      } else if (this.classList.contains('money')) {
        sortFn = moneySortFn;
      } else {
        sortFn = numberSortFn;
      }

      const table = vnode.el.closest('.table');
      if (table) {
        table?._sortFunction?.(sortFn);
      } else {
        context?.columnSort?.(sortFn);
      }
    }

    el._sortClassEventHandler = sortClassEventHandler.bind(el, sortKey, binding.instance, vnode);
    el.addEventListener('click', el._sortClassEventHandler);
  },
  unmounted(el) {
    el.removeEventListener('click', el._sortClassEventHandler);
    el._sortClassEventHandler = null;
  },
};
<template>
  <template v-if="showPreloader && !finished">
    <slot name="preloader">
      <inline-svg :src="require('@/assets/spinner.svg')" stroke="currentColor" width="42" height="42" class="preloader"/>
    </slot>
  </template>
  <template v-else>
    <template v-if="error">
      <div v-if="showError" class="notification notification--error mb-3">
        <h5 class="notification__title">Error 🧐</h5>
        <span class="notification__error">{{ error }}</span>
      </div>
      <slot v-else name="error" :error="error"/>
    </template>
    <template v-else>
      <slot name="content" :data="data"/>
    </template>
  </template>
</template>

<script>
export default {
  emits: ['done', 'ready'],
  props: {
    /**
     * Name of store action to invoke
     */
    invoke: {
      type: String,
      required: true,
    },
    /**
     * Object of arguments to pass to the invoke function
     */
    args: {
      type: Object,
      default: () => {},
    },
    /**
     * Hide outputting of errors - if the promise fails
     */
    showError: {
      type: Boolean,
      default: true,
    },
    /**
     * Toggle to show svg preloader indicator when waiting for request
     */
    showPreloader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      finished: false,
      error: null,
      data: {},
    };
  },
  created() {
    this.$store.dispatch(this.invoke, this.args)
      .then(data => this.data = data)
      .catch(err => this.error = err.message)
      .finally(() => {
        this.finished = true;
        this.$emit('done', this.data);

        // TODO : remove me soon. this is just a temporary event so we can use composition API to update meta title.
        //        Some things use the @done event without composition so we don't want to refactor those bits.
        //        SEE https://github.com/nuxt/vue-meta/issues/699
        this.$emit('ready', this.data);
      });
  },
}
</script>

<style lang="scss" scoped>
.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
<template>
  <metainfo>
    <template #title="{ metainfo }">{{ metainfo.titleTemplate(metainfo.title) }}</template>
  </metainfo>
  <header class="header">
    <div class="header__brand d-flex align-items-center">
      <button class="hamburger hamburger--slider d-lg-none mr-4"
              :class="{'is-active': sidebarVisible}"
              type="button"
              aria-label="Menu" aria-controls="sidebar" :aria-expanded="sidebarVisible"
              @click="sidebarVisible = !sidebarVisible">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
      <router-link to="/" class="header__logo d-flex align-items-center">
        <img src="@/assets/chatter-logo-square.svg" width="36" height="36" alt="Chatter Logo" />
        <span class="ml-3">Client Portal</span>
      </router-link>
    </div>
    <Search v-if="user"/>
    <details v-if="user" class="details-dropdown">
      <summary>
        <div class="userinfo">
          <Avatar :src="user.avatar" :string="user.name" class="mr-2"/>
          <span class="userinfo__name">{{ user.name }}</span>
        </div>
      </summary>
      <div class="details-dropdown__container details-dropdown__container--align-right">
        <ul>
          <!--<li><router-link to="/edit-profile">Edit Profile</router-link></li>-->
          <li><a href="/logout">Logout</a></li>
        </ul>
      </div>
    </details>
  </header>
  <aside id="sidebar" class="sidebar" :class="{'visible': sidebarVisible}">
    <!-- portal for components to register custom links -->
    <portal-target name="sidebar"/>
    <!-- upsell products -->
    <div class="sidebar__products">
      <h3 class="sidebar__products__title">Did you know?</h3>
      <p>We offer more than award winning campaigns, we've also been beavering away on a suite of useful products:</p>
      <div class="sidebar__products__adverts">
        <div class="sidebar__products__adverts__advert">
          <h4>Readability tool</h4>
          <img src="https://picsum.photos/250/104?grayscale" alt="desc of image" title="desc of image" />
          <p>Improve your job adverts with our new tool for improving your job listings by checking reading age, gendered word use, and more.</p>
          <p><a class="button button--sm" href="https://readability.apps.welovechatter.com" target="_blank" rel="noopener">Find out more</a></p>
        </div>
      </div>
    </div>
  </aside>
  <main class="main">
    <router-view/>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import Search from '@/components/search.vue'
import Avatar from '@/components/avatar.vue'

export default {
  name: 'App',
  components: {
    Search,
    Avatar,
  },
  metaInfo() {
    return {
      title: '',
      titleTemplate(title) {
        if (title.length === 0) {
          return 'Chatter Client Dashboard';
        }
        
        return `${title} - Chatter Client Dashboard`;
      },
    };
  },
  data() {
    return {
      sidebarVisible: false,
    };
  },
  created() {
    this.$store.dispatch('me');

    // close any <details> dropdowns once we click away
    window.addEventListener('click', (event) => {
      const path = event.composedPath();

      Array.from(document.querySelectorAll('.details-dropdown'))
        // only target open <details> tags
        .filter(el => el.open)
        // ensure the bubbled path does not contain a <summary> tag (as that will automatically close it for us!)
        .filter(() => path.every(node => node.nodeName !== 'SUMMARY'))
        // close <details> tag
        .forEach(el => el.open = false);
    });
  },
  watch: {
    '$route': function() {
      this.sidebarVisible = false;

      // if loginsuccess query is set (probably from google login return), redirect to the stored location
      const { redirectedFrom } = this.$route;
      if (typeof redirectedFrom?.query?.loginsuccess !== 'undefined') {
        const redirect = localStorage.getItem('redirect');
        if (redirect) {
          this.$router.replace(redirect);
        }
      }
    },
  },
  computed: mapState([
    'user',
  ]),
};
</script>

<style lang="scss">
#app {
  display: grid;
  grid-template-columns: $sidebar-width repeat(3, 1fr);
  grid-template-rows: auto 1fr;
  min-height: inherit;
}

.main {
  position: relative;
  grid-column: 1 / -1;
  grid-row-start: 2;
  padding-top: $header-height;

  @media (min-width: $breakpoint-lg) {
    grid-column-start: 2;
  }

  .main-header {
    padding: 35px 45px;
    background-color: #fff;
    border-bottom: 2px solid #f0f0f0;
    z-index: $main-header-z;

    .userinfo__image-container {
      margin-right: 25px;
    }

    &__title {
      font-size: 24px;
      font-weight: 900;
      margin: 0;
    }

    &__subtitle {
      font-size: 16px;
      margin: 0;

      span {
        color: #8e8e8e;
      }
    }

    .button {
      font-size: 12px;
      // margin-top: 8px;

      // &:is(:focus, :hover) {
      //   color: #fff;
      // }
    }
  }

  .main-content {
    padding: 25px;
  }
}

.client-project {
  &__details {
    font-size: 11px;
    text-align: center;
  }

  &__due-date {
    color: #8d8d8d;
  }
}

.status-bubble {
  font-weight: 700;
  text-transform: uppercase;
  color: $colour-brand;
  padding: 5px 10px;
  border: 1px solid $colour-brand;
  border-radius: 6px;
  white-space: pre;

  &--green {
    color: $green;
    border-color: $green;
  }

  &--orange {
    color: $orange;
    border-color: $orange;
  }

  &--red {
    color: $red;
    border-color: $red;
  }

  &--sm {
    font-size: 10px;
    padding: 3px 5px 2px;
  }

  &--xs {
    font-size: 10px;
    padding: 1px 5px;
  }
}

.userinfo {
  display: flex;
  align-items: center;

  &__image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    font-size: 8px;
    background: #f0f0f0;
    border-radius: 50%;

    &--md {
      font-size: 10px;
      width: 42px;
      height: 42px;
    }

    &--lg {
      font-size: 12px;
      width: 52px;
      height: 52px;
    }

    &--xl {
      font-size: 22px;
      width: 86px;
      height: 86px;
    }
  }

  &__image {
    width: inherit;
    height: inherit;
    border-radius: inherit;
    object-fit: cover;
  }

  &__initials {
    font-weight: 800;
    color: #8e8e8e;
  }

  &__details-container {
    text-align: left;
  }

  &__name {
    margin: 0;
  }

  &__email {
    display: block;
    margin: 3px 0 0 0;
    color: #696969;
  }
}

.capitalize {
  text-transform: capitalize;
}
</style>
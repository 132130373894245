<template>
  <!--<portal to="sidebar">
    <h3 class="sidebar__nav-heading">Subnavigation</h3>
    <nav class="sidebar__nav">
      <a href="#">Link</a>
      <a href="#">Link</a>
      <a href="#">Link</a>
      <a href="#">Link</a>
    </nav>
  </portal>-->
  <section class="main-content">
    <promise invoke="getAllClients">
      <template #content="props">
        <h3 class="mt-0">Clients</h3>
        <details v-for="client in props.data" :key="client.id" class="clients__item">
          <summary>
            <router-link :to="`/client/${client.id}`" class="clients__item__title">{{ client.clientName }}</router-link>
          </summary>
          <table class="table table--sm table--align-left" v-table-sort="fn => client.jobs.sort(fn)">
            <thead>
              <tr>
                <th width="12%">Job Number</th>
                <th>Job Name</th>
                <th width="15%" class="string" v-column-sort:startDate>Start Date</th>
                <th width="18%" class="string" v-column-sort:manager>Account Handler</th>
                <th width="12%" class="string" v-column-sort:state>State</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="job in client.jobs" :key="job.jobUuid">
                <td><router-link :to="`/project/${job.jobUuid}`">{{ job.jobRef }}</router-link></td>
                <td><router-link :to="`/project/${job.jobUuid}`">{{ job.jobName }}</router-link></td>
                <td>{{ formatDate(job.startDate) }}</td>
                <td>{{ job.manager }}</td>
                <td>{{ job.state }}</td>
              </tr>
            </tbody>
          </table>
        </details>
      </template>
    </promise>
  </section>
</template>

<script>
import Promise from '@/components/promise.vue'
import { formatDate } from '@/utils'

export default {
  components: {
    Promise,
  },
  methods: {
    formatDate,
  },
}
</script>

<style lang="scss" scoped>
.clients {
  margin: 0;
  padding: 0;

  &__item {
    margin-bottom: 5px;

    summary {
      position: relative;
      padding: 10px 10px 10px 25px;
      background-color: #eee;
      border-radius: $global-border-radius;
      list-style-type: none;

      &::marker {
        display: none;
      }

      &::-webkit-details-marker {
        display: none;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 10px;
        width: 0;
        height: 0;
        margin-top: -4px;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 6px solid #000;
      }
    }

    &[open] {
      summary {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &::after {
          transform: rotateZ(90deg);
        }
      }
    }

    .table {
      th {
        &:first-child,
        &:last-child {
          border-radius: 0;
        }
      }
    }
  }
}
</style>
import { createApp } from 'vue'
import PortalVue from 'portal-vue'
import InlineSvg from 'vue-inline-svg'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import App from './App.vue'
import store from './store'
import router from './router'
import './scss/style.scss'

import {
  OpenModalDirective,
  ModalMixin,
  TableSortDirective,
  ColumnSortDirective,
} from './plugins';

const metaManager = createMetaManager();

createApp(App)
  .use(store)
  .use(router)
  .use(PortalVue)
  .use(metaManager)
  .use(metaPlugin)
  .component('inline-svg', InlineSvg)
  .directive('open-modal', OpenModalDirective)
  .directive('table-sort', TableSortDirective)
  .directive('column-sort', ColumnSortDirective)
  .mixin(ModalMixin)
  .mount('#app');

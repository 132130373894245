const FETCH_CACHE_EXPIRES = 30_000; // cache fetch requests for 30 seconds
const fetchCache = {};

/**
 * Flush any expired GET cache entries
 */
function flushCache() {
  const now = Date.now();
  for (const url in fetchCache) {
    if (now >= fetchCache[url].expires) {
      delete fetchCache[url];
    }
  }
}

/**
 * Ensure response from fetch is 200 OK
 */
function ensureOKResponse(res) {
  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res;
}

/**
 * Clear error messages returned from API
 */
function responseErrorTestHandler(res) {
  if (res && res.message) {
    let str = `${res.message}`;

    // was an exception, append file name & line number
    if (res.exception) {
      str += `\n${res.file} at line ${res.line}.`;
    }

    console.error(str);
    throw new Error(str);
  }

  return res;
}

/**
 * Perform a GET request
 */
export function httpGet(url, { force = false } = {}) {
  flushCache();

  const now = Date.now();
  if (!force && typeof fetchCache[url] !== 'undefined' && (now < fetchCache[url].expires)) {
    return fetchCache[url].response;
  }

  const options = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${document.body.dataset.token}`,
    },
  };

  const response = fetch(`/api/v1${url}`, options)
    .then(ensureOKResponse)
    .then(res => res.json())
    .then(responseErrorTestHandler);
  
  fetchCache[url] = {
    expires: (now + FETCH_CACHE_EXPIRES),
    response: response,
  };

  // delete cache if fetch fails
  response.catch(() => delete fetchCache[url]);

  return response;
}

/**
 * Perform a POST request
 */
export function httpPost(url, data, opts = {}) {
  const options = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${document.body.dataset.token}`,
    },
    body: data,
    ...opts,
  };

  const response = fetch(`/api/v1${url}`, options)
    .then(ensureOKResponse)
    .then(res => res.json())
    .then(responseErrorTestHandler);

  return response;
}

/**
 * Perform a DELETE request
 */
export function httpDelete(url) {
  const options = {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${document.body.dataset.token}`,
    },
  };

  const response = fetch(`/api/v1${url}`, options)
    .then(ensureOKResponse)
    .then(res => res.json())
    .then(responseErrorTestHandler);

  return response;
}
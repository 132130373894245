<template>
  <div class="userinfo__image-container">
    <img v-if="!fallback" class="userinfo__image" width="26" height="26" :alt="alt ? alt : 'User avatar'" ref="img"/>
    <span v-else-if="string" class="userinfo__initials" aria-hidden="true">{{ getInitials(string) }}</span>
    <span v-else class="userinfo__initials" aria-hidden="true">?</span>
  </div>
</template>

<script>
import { getInitials } from '@/utils'

export default {
  props: {
    src: {
      type: String,
    },
    string: {
      type: String,
    },
    alt: {
      type: String,
    },
  },
  data() {
    return {
      // if we didn't pass a source for the image, just use the fallback straight away!
      fallback: !this.src ? true : false,
    };
  },
  mounted() {
    if (this.src) {
      this.setImageSrc(this.src);
    }
  },
  methods: {
    getInitials,
    setImageSrc(src) {
      const { img } = this.$refs;
      img.onerror = () => this.fallback = true;
      img.src = src;
    },
  },
  watch: {
    src: function(value) {
      this.fallback = false;
      this.$nextTick(() => this.setImageSrc(value));
    },
  },
}
</script>
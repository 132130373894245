import { createStore } from 'vuex'
import { httpGet } from '@/http'

export default createStore({
  state: {
    user: null,
    chatterinos: [],
    clients: [],
  },
  mutations: {
    setUserData(state, data) {
      state.user = data;
    },
    setChatterinos(state, data) {
      state.chatterinos = data;
    },
  },
  actions: {
    me({ commit }) {
      httpGet('/users/me')
        .then(data => commit('setUserData', data))
        .catch(() => {
          if (process.env.NODE_ENV === 'development') {
            commit('setUserData', {
              name: 'User Name',
              wfm_client_uuid: '9355be3c-15ab-4063-88b9-e79a04b3e9ee',
            });
          }
        });
    },
    getAllClients() {
      return new Promise((resolve, reject) => {
        httpGet('/jobs')
          .then((data) => {
            const mappedData = Object.keys(data)
              .map((clientId) => ({ id: clientId, ...data[clientId] }));
            
            resolve(mappedData);
          })
          .catch(err => reject(err));
      });
    },
    getClient(_store, { id }) {
      return new Promise((resolve, reject) => {
        httpGet(`/client/${id}`)
          .then((data) => {
            // TODO : might want to ensure we have 'status' => 'ok' in all the success results.
            //        /client/{uuid} doesn't so if there're any status messages, it's probably
            //        an error!
            if (data.status) {
              throw new Error(data.status);
            }

            // remap quotes object into an array
            // NOTE : we don't do this at an API level, because we use the key to add child quotes
            //        we need the quotes as an array to ensure sorting works!
            data.quotes = Object.values(data.quotes);
            
            console.log(data);
            resolve(data);
          })
          .catch(err => reject(err));
      });
    },
    getJob(_store, { id }) {
      return new Promise((resolve, reject) => {
        httpGet(`/job/${id}`)
          .then((data) => {
            // TODO : might want to ensure we have 'status' => 'ok' in all the success results.
            //        /job/{uuid} doesn't so if there're any status messages, it's probably
            //        an error!
            if (data.status) {
              throw new Error(data.status);
            }

            console.log(data);
            resolve(data);
          })
          .catch(err => reject(err));
      });
    },
    getChatterinos({ commit }) {
      return new Promise((resolve, reject) => {
        httpGet(`/users/chatterinos`)
          .then((data) => {
            commit('setChatterinos', data);
            resolve(data);
          })
          .catch(err => reject(err));
      });
    },
  },
});
<template>
  <div class="search">
    <input type="search" name="search" class="form-control" v-model="terms" autocomplete="off" :placeholder="placeholder"/>
    <promise invoke="getAllClients" :showError="false" :showPreloader="false">
      <template #content="props">
        <div v-if="terms.length > 1" class="search__results">
          <template v-if="this.user.type === 'chatter'">
            <ul>
              <li v-for="client in search(props.data)" :key="client.id">
                <router-link :to="client.url">{{ client.name }}</router-link>
                <!-- sub nav -->
                <ul v-if="client.jobs.length > 0">
                  <li v-for="(job, idx) in client.jobs" :key="idx">
                    <router-link :to="job.url">{{ job.name }}</router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </template>
          <template v-else>
            <ul v-for="client in search(props.data)" :key="client.id" class="search__results__simple">
              <li v-for="(job, idx) in client.jobs" :key="idx">
                <router-link :to="job.url">{{ job.name }}</router-link>
              </li>
            </ul>
          </template>
        </div>
      </template>
      <template #error="props">
        <div v-if="terms.length > 1" class="search__results">
          <p class="search__results__error"><strong>Error:</strong> {{ props.error }}</p>
        </div>
      </template>
    </promise>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Promise from '@/components/promise.vue'

export default {
  components: {
    Promise,
  },
  data() {
    return {
      terms: '',
      placeholder: 'Search by Job Name/Reference',
    };
  },
  mounted() {
    if (this.user.type === 'chatter') {
      this.placeholder = 'Search by Client Name or Job Name/Reference';
    }
  },
  methods: {
    search(clients) {
      if (this.terms.length < 2) {
        return [];
      }

      // TODO : if client is not a Chatter staff member, we only want to be searching thru their job refs!
      const isChatterStaff = (this.user.type === 'chatter');
      const projectRoute = (isChatterStaff ? 'project' : 'client-project');

      const terms = this.terms.toLowerCase();
      const result = [];

      for (const { id, clientName, jobs } of clients) {
        let containsSomething = false;

        const client = {};
        client.id = id;
        client.jobs = [];

        // search client name if we're logged in as Chatter staff
        if (isChatterStaff) {
          client.name = clientName;

          if (clientName.toLowerCase().includes(terms)) {
            containsSomething = true;
          }
        }

        for (const { jobRef, jobUuid, jobName } of jobs) {
          if (jobRef.toLowerCase().includes(terms) || jobName.toLowerCase().includes(terms)) {
            client.jobs.push({
              name: `${jobRef} - ${jobName}`,
              url: `/${projectRoute}/${jobUuid}`,
            });

            containsSomething = true;
          }
        }

        if (containsSomething) {
          if (isChatterStaff) {
            client.url = `/client/${id}`;
          }
          
          result.push(client);
        }
      }

      return result;
    },
  },
  watch: {
    '$route': function() {
      // TEMP : reset terms to close the search results box.
      //        This is probably not very good UX, but will do for now..
      this.terms = '';
    },
  },
  computed: mapState([
    'user',
  ]),
}
</script>
import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '@/views/chatter/dashboard.vue'
import NotFound from '@/views/404.vue'
import { httpGet } from '@/http'

/**
 * Routes protected by Chatter staff authentication (via Google)
 */
function ensureChatterAuth(to, _from, next) {
  httpGet('/users/me', { force: true })
    .then(({ type }) => {
      if (type === 'chatter') next();
      else throw new Error(`User is not a Chatterino`);
    })
    .catch(() => {
      next({
        path: '/login/chatter',
        query: {
          redirect: to.fullPath,
        },
      });
    });
}

/**
 * Routes protected for client authentication (via Magic link)
 */
function ensureClientAuth(to, _from, next) {
  httpGet('/users/me', { force: true })
    .then(({ type }) => {
      if (type === 'client' || type === 'chatter') next();
      else throw new Error(`User is not a authenticated.`);
    })
    .catch(() => {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      });
    });
}

/**
 * Redirect user away from current page if they're logged in
 */
function redirectIfLoggedIn(_to, _from, next) {
  httpGet('/users/me', { force: true })
    .then(({ type }) => {
      next({
        path: type === 'chatter' ? '/dashboard' : '/client-dashboard',
      });
    })
    .catch(() => next());
}

const routes = [
  {
    path: '/',
    name: 'root',
    beforeEnter(_to, _from, next) {
      httpGet('/users/me', { force: true })
        .then(({ type }) => next({ path: type === 'chatter' ? '/dashboard' : '/client-dashboard' }))
        .catch(() => next({ path: '/login' }));
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: ensureChatterAuth,
  },
  {
    path: '/client/:id',
    alias: '/client/:id/quote/:quote_ref?',
    name: 'chatter-client',
    component: () => import('@/views/chatter/client.vue'),
    beforeEnter: ensureChatterAuth,
  },
  {
    path: '/project/:id',
    name: 'chatter-project',
    component: () => import('@/views/chatter/project.vue'),
    beforeEnter: ensureChatterAuth,
  },
  {
    path: '/client-dashboard',
    alias: [
      '/client-dashboard/quote/:quote_ref?',
      '/client-dashboard/quote/:quote_ref?/:action?'
    ],
    name: 'client-dashboard',
    component: () => import('@/views/client/dashboard.vue'),
    beforeEnter: ensureClientAuth,
  },
  {
    path: '/client-project/:id',
    name: 'client-project',
    component: () => import('@/views/client/project.vue'),
    beforeEnter: ensureClientAuth,
  },
  // {
  //   path: '/edit-profile',
  //   name: 'edit-profile',
  //   component: () => import('@/views/user/profile.vue'),
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    beforeEnter: redirectIfLoggedIn,
    children: [
      {
        path: 'chatter',
        component: () => import('@/views/login/chatter.vue'),
      },
      {
        path: 'magic-link-expired',
        component: () => import('@/views/login/magic-link-expired.vue'),
      },
      {
        path: '',
        component: () => import('@/views/login/client.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: NotFound,
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory('/'),
  routes,
});

export default router;
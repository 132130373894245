/**
 * Get name initials from the full name
 */
export function getInitials(name) {
  // clean whitespaces and replace any double spaces with single spaces.
  // NOTE : this is just cleaning up some things from WFM
  const cleanName = name.trim().replaceAll('  ', ' ');

  const parts = cleanName.split(' ');
  return parts.map(part => part[0].toUpperCase()).slice(0, 2).join('');
}

/**
 * Transform string into a class name string
 */
export function classifyString(string) {
  return string.toLowerCase().replaceAll(' ', '-');
}

/**
 * Helper function to check if an object is empty
 */
export function isEmptyObject(obj) {
  if (!obj) {
    return true;
  }
  
  return Object.keys(obj).length === 0;
}

/**
 * Format date string from API
 */
export function formatDate(date) {
  return new Date(date).toLocaleDateString('en-gb');
}

/**
 * Format date string for input date element
 */
export function formatDateForInput(date) {
  return date.split(' ')[0];
}

/**
 * Format price string 
 */
export function formatPrice(price) {
  return parseInt(price, 10).toLocaleString('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });
}

/**
 * Get a value from a nested object similar to lodash's _.get function
 *
 * get({ x: { y: 0 } }, ['x', 'y', 'z'], [])
 * => []
 *
 */
 export function get(obj, keys, def) {
  const tmp = obj[keys[0]];

  if (keys.length > 1 && tmp) {
    keys.shift();
    return get(tmp, keys) || def;
  }

  return tmp || def;
}

/**
 * Slugify quotation ref by replacing all forward slashes with a dash
 */
export function slugifyQuote({ quote_ref }) {
  return quote_ref.replaceAll('/', '-');
}

/**
 * Unslugify quotation ref by replacing all dashes with a forward slash
 */
export function unslugifyQuote(quote_ref) {
  return quote_ref.replaceAll('-', '/');
}

/**
 * Strip formatting from currency and return a Number
 */
export function unformatCurrency(money) {
  return Number(money.replace(/[^0-9.]+/g, ''));
}

/**
 * Check if we have atleast one primary contact in an array of contacts
 */
export function hasAtleastOnePrimaryContact(contacts) {
  const primaryContacts = contacts.filter(contact => contact.isPrimary);
  return primaryContacts.length !== 0;
}

/**
 * Transform checkbox values from on/off to true/false
 */
export function transformCheckboxValues(formData) {
  const keysToCheck = [
    'requires_signoff',
    'shareable_with_client',
  ];

  keysToCheck.forEach((key) => {
    if (formData.has(key)) {
      formData.set(key, formData.get(key) === 'on' ? 'true' : 'false');
    }
  });
}

/**
 * A bit of a hack to fix an issue with the way we're doing things.
 * Because once the ajax-modal component re-renders, the OLD value
 * (bound from :value) will not be updated and we'll see a flash of
 * the initial value, which looks a bit pants.
 *
 * This function just duplicates the input elements and shows the new modified
 * values.
 */
export function duplicateFields(formData, form) {
  const duplicated = [];

  for (const key of formData.keys()) {
    const el = form.querySelector(`[name=${key}]`);

    // clone the element
    const newNode = el.cloneNode(true);
    newNode.removeAttribute('id');

    el.style.display = 'none';
    el.after(newNode);

    duplicated.push({
      el,
      cloned: newNode,
    });
  }

  return duplicated;
}